import { Config } from "../../Config";
import {
    FaceTecIDScanResult,
    FaceTecSessionResult
} from "../../core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { FaceTecSDK } from "../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import {
    ApplicantData,
    FlowRequest,
    FlowStep,
    OCRScanData,
    WorkflowVerificationRequestDto
} from "../interfaces";
import { trimStringToArray } from "../utilities/utils";
import {
    BaseController,
    sdkFailureIconImage,
    sdkSuccessIconImage
} from "./baseController";

const sdkApplicatDataEjs: string = require("../../templates/sdkApplicantData.ejs");
const sdkProductListEjs: string = require("../../templates/sdkProductList.ejs");

export class WorkflowController extends BaseController {
    readonly controllerType = "workflow";
    lastSumbittedFormData: WorkflowVerificationRequestDto = {} as any;

    async startVerification(): Promise<boolean> {
        this.updateFinalSubmitButton("Please wait...", true);
        this.lastSumbittedFormData = { sessionStartedAt: new Date() } as any;
        let flowRequestData = await this.apiRequest<FlowRequest>(
            `${Config.ApiBaseUrl}/v1/sdk/workflows/${this.sdkElementData.flowId}/${this.sdkElementData.customerReference}`,
            "GET",
            {},
            true,
            this.sdkInitializationData?.token?.accessToken
        ).catch((error) => {
            console.error(error.message);
            return null as any as FlowRequest;
        });

        if (flowRequestData?.id) {
            const eventData = {
                verificationId: flowRequestData.id,
                customerReference: flowRequestData.customerReference,
                workflowId: flowRequestData.workflowMetadata?.id,
                requestCompleted: flowRequestData.requestCompleted,
                expired: flowRequestData.expired
            };
            const customEvent = new CustomEvent(
                "qoreid:verificationInitialized",
                { detail: eventData }
            );
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.sdkElementData.initializedEventTrigger(customEvent);
            this.triggerTopMainElementEvent(customEvent);
        }

        return this.processFlowRequestData(flowRequestData);
    }

    async showApplicantForm(): Promise<boolean> {
        this.updateFinalSubmitButton();
        // render the screen
        const htmlString = await this.getEjs()
            .render(
                sdkApplicatDataEjs,
                {
                    applicantFields: [
                        {
                            code: "firstname",
                            label: "Firstname",
                            isOptional: false,
                            defaultValue:
                                this.sdkElementData.applicantData?.firstname ||
                                "",
                            validationTest: ""
                        },
                        {
                            code: "middlename",
                            label: "Middlename",
                            isOptional: true,
                            defaultValue:
                                this.sdkElementData.applicantData?.middlename ||
                                "",
                            validationTest: ""
                        },
                        {
                            code: "lastname",
                            label: "Lastname",
                            isOptional: false,
                            defaultValue:
                                this.sdkElementData.applicantData?.lastname ||
                                "",
                            validationTest: ""
                        },
                        {
                            code: "phone",
                            label: "Phone Number",
                            isOptional: false,
                            defaultValue:
                                this.sdkElementData.applicantData?.phone || "",
                            validationTest: ""
                        }
                    ]
                },
                { async: true }
            )
            .catch((e) => {
                console.error(e);
                return "<h3>Error loading request data</h3>";
            });
        this.setSdkBodyElement(htmlString);
        return true;
    }

    setApplicantData(formElement: HTMLFormElement) {
        this.setSdkStatusElement("");
        const applicantData = {} as any;

        for (const key in formElement.elements) {
            if (String(key) != Number(key).toString()) continue;
            const element: HTMLInputElement = formElement.elements.namedItem(
                (formElement.elements[key] as any).name
            ) as any;
            if (
                element?.name &&
                String(element.id).indexOf("applicantFields_") === 0
            ) {
                const fieldName = String(element.id).replace(
                    "applicantFields_",
                    ""
                ) as keyof ApplicantData;
                applicantData[fieldName] = (String(
                    element.value || ""
                ).trim() || undefined) as any;
            }
        }

        this.createFlowRequest(applicantData);
    }

    async createFlowRequest(applicantData?: ApplicantData): Promise<boolean> {
        if (!this.finalSubmitButton) {
            this.finalSubmitButton = window.document.getElementById(
                "sdkVerificationFormSubmitButton"
            ) as HTMLButtonElement;
        }

        this.updateFinalSubmitButton("Please wait...", true);
        const flowRequestData: FlowRequest = await this.apiRequest<FlowRequest>(
            `${Config.ApiBaseUrl}/v1/sdk/workflows`,
            "POST",
            {
                workflowId: this.sdkElementData.flowId,
                customerReference: this.sdkElementData.customerReference,
                applicant: this.sdkInitializationData?.metadata
                    ?.applicantDataRequired
                    ? applicantData || this.sdkElementData.applicantData
                    : undefined
            },
            true,
            this.sdkInitializationData?.token?.accessToken
        ).catch(async (error) => {
            this.setSdkStatusElement(
                `<div class="sdkErrorMessage">${
                    error.message ||
                    "Unable to initialize a verification process at the moment"
                }</div>`
            );

            const customEvent = new CustomEvent("qoreid:verificationError", {
                detail: error
            });
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.sdkElementData.errorEventTrigger(customEvent);
            this.triggerTopMainElementEvent(customEvent);

            return null as any;
        });

        if (flowRequestData?.id) {
            const eventData = {
                verificationId: flowRequestData.id,
                customerReference: flowRequestData.customerReference,
                workflowId: flowRequestData.workflowMetadata?.id,
                requestCompleted: flowRequestData.requestCompleted,
                expired: flowRequestData.expired
            };
            const customEvent = new CustomEvent(
                "qoreid:verificationInitialized",
                { detail: eventData }
            );
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.sdkElementData.initializedEventTrigger(customEvent);
            this.triggerTopMainElementEvent(customEvent);
            if (applicantData)
                this.sdkElementData.applicantData = applicantData;

            return this.processFlowRequestData(flowRequestData);
        }

        return false;
    }

    async processFlowRequestData(
        flowRequestData: FlowRequest
    ): Promise<boolean> {
        this.updateFinalSubmitButton();
        if (!flowRequestData?.id || flowRequestData?.expired) {
            if (
                !this.sdkInitializationData.sdkSettings.displayApplicantForm &&
                (!this.sdkElementData.applicantData?.lastname ||
                    !this.sdkElementData.applicantData?.phone)
            ) {
                this.setSdkStatusElement(
                    `<div class="sdkErrorMessage">Applicant data not complete</div>`
                );
                return false;
            } else if (
                this.sdkInitializationData?.metadata?.applicantDataRequired &&
                this.sdkInitializationData.sdkSettings.displayApplicantForm
            ) {
                return this.showApplicantForm();
            } else {
                return this.createFlowRequest();
            }
        }

        if (flowRequestData.requestCompleted) {
            this.setSdkBodyElement(`
            <div>
                <img src="${sdkSuccessIconImage}" alt="Successful" width="80" />
                <div class="sdkSuccessMessage">Verification was previously submitted and still in progress</div>
                <br>
                <div>
                    <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">Close</button>
                </div>
            </div>
            `);
            return true;
        }

        this.lastSumbittedFormData = {
            ...this.lastSumbittedFormData,
            verificationId: flowRequestData.id,
            workflowId: flowRequestData.workflowMetadata.id,
            source: this.sdkSource,
            sdkVersion: this.sdkVersion,
            deviceFingerprint: this.getBrowserFingerPrint(),
            step: {} as any
        };

        this.flowRequest = flowRequestData;

        const products = this.flowRequest.step.products || [];
        if (products.length) {
            let firstProduct = products[0];
            if (products.length > 1) {
                if (!this.sdkElementData.identityData?.requiredIdType) {
                    return this.showSelectProductPage(this.flowRequest.step);
                }
                const clientProductCode =
                    "verify_" +
                    this.sdkElementData.identityData?.requiredIdType;
                const requiredProductType = products.find(
                    (p) => p.code == clientProductCode
                );
                if (!requiredProductType?.code) {
                    return this.showSelectProductPage(this.flowRequest.step);
                } else {
                    firstProduct = requiredProductType;
                }
            }

            this.flowRequest.step.selectedProduct = firstProduct;
            this.flowRequest.step.selectedProduct.stepCounter = {
                stepNumber: this.flowRequest.step.stepNumber,
                stepCount: this.flowRequest.stepsCount
            };
            this.renderSdkRequestBody(this.flowRequest.step.selectedProduct);
            return true;
        } else {
            this.setSdkStatusElement(
                `<div class="sdkErrorMessage">error processing verification step (${this.flowRequest.step.stepNumber})</div>`
            );
        }
        return false;
    }

    async showSelectProductPage(step: FlowStep): Promise<boolean> {
        this.updateFinalSubmitButton();
        // render the screen
        const htmlString = await this.getEjs()
            .render(
                sdkProductListEjs,
                {
                    ...step,
                    stepCounter: {
                        stepNumber: this.flowRequest.step.stepNumber,
                        stepCount: this.flowRequest.stepsCount
                    }
                },
                { async: true }
            )
            .catch((e) => {
                console.error(e);
                return "<h3>Error loading request data</h3>";
            });
        this.setSdkBodyElement(htmlString);
        return true;
    }

    setCurrentProduct(productCode?: string) {
        if (!productCode) return;
        this.flowRequest.step.selectedProduct =
            this.flowRequest.step.products.filter(
                (p) => p.code === productCode
            )[0];

        if (!this.flowRequest.step.selectedProduct?.code) return;
        this.flowRequest.step.selectedProduct.stepCounter = {
            stepNumber: this.flowRequest.step.stepNumber,
            stepCount: this.flowRequest.stepsCount
        };
        this.renderSdkRequestBody(this.flowRequest.step.selectedProduct);
    }

    onComplete(
        sessionResult?: FaceTecSessionResult,
        idScanResult?: FaceTecIDScanResult,
        _latestNetworkResponseStatus?: number,
        _latestIDScanOCRResult?: OCRScanData
    ) {
        if (this.lastSumbittedFormData) {
            this.lastSumbittedFormData.step.fields.biometrics = {
                flowRequestId: this.logId || undefined
            };

            if (idScanResult) {
                this.lastSumbittedFormData.step.fields.biometrics = {
                    ...this.lastSumbittedFormData.step.fields.biometrics,
                    scanResult: {
                        idScanFrontImage: idScanResult.frontImages[0],
                        idScanBackImage: idScanResult.backImages[0],
                        idScan: idScanResult.idScan || "",
                        sessionId: idScanResult.sessionId,
                        minMatchLevel: 3
                    }
                };

                if (this.sdkElementData.ocrAcceptedDocuments) {
                    this.lastSumbittedFormData.step.fields.biometrics.acceptedDocuments =
                        trimStringToArray(
                            this.sdkElementData.ocrAcceptedDocuments
                        );
                }
            }

            if (sessionResult) {
                this.lastSumbittedFormData.step.fields.biometrics = {
                    ...this.lastSumbittedFormData.step.fields.biometrics,
                    sessionResult: {
                        faceScan: sessionResult.faceScan,
                        lowQualityAuditTrailImage:
                            sessionResult.lowQualityAuditTrail[0],
                        auditTrailImage: sessionResult.auditTrail[0]
                    }
                };
            }

            this.lastSumbittedFormData.step.fields.biometrics.xUserAgent =
                FaceTecSDK.createFaceTecAPIUserAgentString(
                    sessionResult?.sessionId || ""
                );
            this.lastSumbittedFormData.step.fields.biometrics.externalDatabaseRefID =
                this.getLatestEnrollmentIdentifier();
        }

        this.submitVerification();

        return null as any;
    }

    async submitVerification(formElement?: HTMLFormElement) {
        this.setSdkStatusElement("");

        if (!this.finalSubmitButton) {
            this.finalSubmitButton = window.document.getElementById(
                "sdkVerificationFormSubmitButton"
            ) as HTMLButtonElement;
        }
        this.updateFinalSubmitButton("Please wait...", true);

        if (!this.lastSumbittedFormData) {
            this.lastSumbittedFormData = {
                sessionStartedAt: new Date(),
                verificationId: this.flowRequest.id,
                workflowId: this.flowRequest.workflowMetadata.id,
                source: this.sdkSource,
                sdkVersion: this.sdkVersion,
                deviceFingerprint: this.getBrowserFingerPrint(),
                step: {} as any
            };
        }

        if (!this.lastSumbittedFormData?.step?.stepNumber) {
            if (!formElement) return;

            this.lastSumbittedFormData.step = {
                stepNumber: this.flowRequest.step.stepNumber,
                serviceCode: this.flowRequest.step.serviceCode,
                productCode: this.flowRequest.step.selectedProduct.code,
                fields: {}
            };

            for (const key in formElement.elements) {
                if (String(key) != Number(key).toString()) continue;
                let element: HTMLInputElement =
                    formElement.elements.namedItem(
                        (formElement.elements[key] as any).name
                    ) as any;
                if(element instanceof RadioNodeList) {
                    element = element.value === 'yes'? element.item(0) : element.item(1) as any
                }
                if (element?.name && !element.disabled && !element.readOnly) {
                    if (String(element.id).indexOf("fields_") === 0) {
                        const fieldName = String(element.id).replace(
                            "fields_",
                            ""
                        );
                        
                        if (element.classList.contains("sdkBooleanField")) {
                            this.lastSumbittedFormData.step.fields[fieldName] =
                                String(element.value || "").trim() == "yes";
                        } else {
                            this.lastSumbittedFormData.step.fields[fieldName] =
                                String(element.value || "").trim() || undefined;
                        }
                    }
                }

                if( this.flowRequest.step.selectedProduct.code === "verify_vehicle_self") {
                    if(['true', 'false'].includes((element as unknown as any)?.value)) {
                        
                        this.lastSumbittedFormData.step.fields['sideMirrorCracks'] = (element as unknown as any)?.value;
                        this.lastSumbittedFormData.step.fields['windscreenCracks'] = (element as unknown as any)?.value;
                    } else if(['fair', 'poor', 'excellent'].includes((element as unknown as any)?.value)) {
                        this.lastSumbittedFormData.step.fields['overallCondition'] = (element as unknown as any)?.value;
                    }
                }
                 
            }


            const productCode = String(
                this.lastSumbittedFormData.step.productCode
            ).replace(/^verify_/, "");

            if (
                this.productCodeHasOcr(productCode) ||
                this.productCodeHasLiveness(productCode) ||
                this.productCodeHasFaceMatch(productCode)
            ) {
                this.launchFaceTec(productCode);
                return;
            }
        }

        // update organisation extra data into request data if available
        this.lastSumbittedFormData.organisationExtraData = this.sdkElementData.extraData || undefined
        
        let response: FlowRequest = await this.apiRequest<FlowRequest>(
            `${Config.ApiBaseUrl}/v1/sdk/workflows`,
            "PUT",
            this.lastSumbittedFormData,
            true,
            this.sdkInitializationData?.token?.accessToken
        ).catch((error) => {
            if (String((error as any)?.data?.devMessage).toLowerCase() !== "timeout has occurred" || ![0, 520].includes(error.code)) 
                return error;
            
            // Repull
            return this.apiRequest<FlowRequest>(
                `${Config.ApiBaseUrl}/v1/sdk/workflows/${this.sdkElementData.flowId}/${this.sdkElementData.customerReference}?ignoreStatus=true`,
                "GET",
                {},
                true,
                this.sdkInitializationData?.token?.accessToken
            ).catch(() => error);
        });

        let customEvent: Event = new Event("qoreid:event");
        if (response?.id && response?.workflowMetadata?.id) {
            if (response.requestCompleted === false) {
                this.processFlowRequestData(response);
                return;
            }

            if (response?.livenessCheck?.isLive === false) {
                (this.logId = response.id),
                    this.launchFaceTec(this.currentProductCode);
                return;
            }

            let htmlString = `
            <div>
                <img src="${sdkSuccessIconImage}" alt="Successful" width="80" />
                <div class="sdkSuccessMessage">Verification Submitted Successfully</div>
                <br>
                <div>
                    <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">OK</button>
                </div>
            </div>
            `;

            if (String(response?.status || "").toLowerCase() == "failed") {
                htmlString = `<div>
                <img src="${sdkFailureIconImage}" alt="Verification Failed" width="80" />
                <div class="sdkSuccessMessage">Verification Failed</div>
                <div class="sdkErrorMessage">${
                    this.flowRequest.step.selectedProduct.displayName
                }: ${response.verificationStatus.status
                    .toUpperCase()
                    .replace("_", " ")}</div>
                <br>
                <div>
                    <!-- <button class="sdkButton" onclick="QoreIDWebSdk.initialize(QoreIDWebSdk)">Retry</button> -->
                    <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">OK</button>
                </div>
            </div>`;
            }

            this.setSdkBodyElement(htmlString);
            customEvent = new CustomEvent("qoreid:verificationSubmitted", {
                detail: response
            });
            this.redirectingData = {
                type: "qoreid:verificationSubmitted",
                detail: response
            };
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.sdkElementData.submittedEventTrigger(customEvent);
        } else {
            this.setSdkBodyElement(`
            <div>
                <img src="${sdkFailureIconImage}" alt="Verification Failed" width="80" />
                <div class="sdkSuccessMessage">Verification Error</div>
                <div class="sdkErrorMessage">${
                    (response as any)?.message || "error verifying your request"
                }</div>
                <br>
                <div>
                    <!-- <button class="sdkButton" onclick="QoreIDWebSdk.initialize(QoreIDWebSdk)">Retry</button> -->
                    <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">OK</button>
                </div>
            </div>
            `);
            customEvent = new CustomEvent<FlowRequest>(
                "qoreid:verificationError",
                { detail: response }
            );
            this.redirectingData = {
                type: "qoreid:verificationError",
                detail: response
            };
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.sdkElementData.errorEventTrigger(customEvent);
        }
        this.toggleWaiterElement(false);
        this.triggerTopMainElementEvent(customEvent);
        this.lastSumbittedFormData.step = null as any;
    }
}
